import styled from '@emotion/styled'
import { staticFileIds, type StaticFileId } from '@orus.eu/dimensions'
import { ContentContainerBackoffice, DownloadButtonWithHref, spacing } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { getStaticFileDownloadLink } from '../../../../../lib/download-links'
import { BackofficeSectionTitle } from '../../../../atoms/backoffice-section-title'

export default memo(function GeneralTermsDocumentationPage() {
  return (
    <ContentContainerBackoffice>
      <BackofficeSectionTitle>Documents statiques</BackofficeSectionTitle>
      <FlexContainer>
        {staticFileIds.map((staticFileId) => (
          <StaticFileDownloadButton key={staticFileId} staticFileId={staticFileId} />
        ))}
      </FlexContainer>
    </ContentContainerBackoffice>
  )
})

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${spacing[20]};
`

const StaticFileDownloadButton = memo<{ staticFileId: StaticFileId }>(function StaticFileDownloadButton({
  staticFileId,
}) {
  const fileName = `${fileNames[staticFileId]}.pdf`
  return (
    <DownloadButtonWithHref
      size="small"
      variant="secondary"
      fileName={fileName}
      href={getStaticFileDownloadLink(staticFileId)}
    />
  )
})

const fileNames: Record<StaticFileId, string> = {
  'restaurants-general-terms': 'Restaurants V1 - Conditions générales',
  'restaurants-ipid': 'Restaurants V1 - IPID',
  'restaurants-v2-general-terms': 'Restaurants V2 - Conditions générales',
  'restaurants-v2-ipid': 'Restaurants V2 - IPID',
  'rc-pro-shared-hiscox-broker-information': 'Hiscox - Information sur l’intermédiaire d’assurance',
  'rc-pro-shared-hiscox-data-policy': 'Hiscox - Politique de protection des données',
  'rc-pro-shared-risk-carrier-information': 'Hiscox - Informations assureur',
  'rc-pro-mac-ipid': 'RC Pro Hiscox V1 - MAC - IPID',
  'rc-pro-man-ipid': 'RC Pro Hiscox V1 - MAN - IPID',
  'rc-pro-media-ipid': 'RC Pro Hiscox V1 - MEDIA - IPID',
  'rc-pro-misc-ipid': 'RC Pro Hiscox V1 - MISC - IPID',
  'rc-pro-miscbie-ipid': 'RC Pro Hiscox V1 - MISCBIE - IPID',
  'rc-pro-tech-ipid': 'RC Pro Hiscox V1 - TECH - IPID',
  'rc-pro-mac-general-terms': 'RC Pro Hiscox V1 - MAC - Conditions générales',
  'rc-pro-man-general-terms': 'RC Pro Hiscox V1 - MAN - Conditions générales',
  'rc-pro-media-general-terms': 'RC Pro Hiscox V1 - MEDIA - Conditions générales',
  'rc-pro-misc-general-terms': 'RC Pro Hiscox V1 - MISC - Conditions générales',
  'rc-pro-miscbie-general-terms': 'RC Pro Hiscox V1 - MISCBIE - Conditions générales',
  'rc-pro-tech-general-terms': 'RC Pro Hiscox V1 - TECH - Conditions générales',
  'mrph-general-terms': 'MRP Hiscox - Conditions générales',
  'mrph-ipid': 'MRP Hiscox - IPID',
  'mrpw-general-terms': 'MRP Wakam - Conditions générales',
  'mrpw-ipid': 'MRP Wakam - IPID',
  'mrpw-fiche-info-distributueur': "MRP Wakam - Fiche d'information distributeur",
  'rcph-mac-general-terms': 'RC Pro Hiscox V2 - MAC - Conditions générales',
  'rcph-man-general-terms': 'RC Pro Hiscox V2 - MAN - Conditions générales',
  'rcph-media-general-terms': 'RC Pro Hiscox V2 - MEDIA - Conditions générales',
  'rcph-misc-general-terms': 'RC Pro Hiscox V2 - MISC - Conditions générales',
  'rcph-secu-general-terms': 'RC Pro Hiscox V2 - SECU - Conditions générales',
  'rcph-secu-ipid': 'RC Pro Hiscox V2 - SECU - IPID',
  'rcph-tag-general-terms': 'RC Pro Hiscox V2 - TAG - Conditions générales',
  'rcph-tag-ipid': 'RC Pro Hiscox V2 - TAG - IPID',
  'rcph-ecom-general-terms': 'RC Pro Hiscox V2 - ECOM - Conditions générales',
  'rcph-ecom-ipid': 'RC Pro Hiscox V2 - ECOM - IPID',
  'rcph-mrp-ipid': 'RC Pro Hiscox V2 - MRP - IPID',
  'rcph-mrp-general-terms': 'MRP Hiscox - Conditions générales',
  'rcph-tech-general-terms': 'RC Pro Hiscox V2 - TECH - Conditions générales',
  'muta-esse-general-terms': 'Mutuelle Acheel - Essentiel - Notice valant CG',
  'muta-ipid': 'Mutuelle Acheel - IPID',
  'muta-priv-general-terms': 'Mutuelle Acheel - Privilege - Notice valant CG',
  'muta-statuts-asso': 'Mutuelle Acheel - Status ACPA',
  'muta-ESSE-example': 'Mutuelle Acheel - Formule Essentiel - Exemple',
  'muta-PRIV-1-example': 'Mutuelle Acheel - Formule Privilège 1 - Exemple',
  'muta-PRIV-2-example': 'Mutuelle Acheel - Formule Privilège 2 - Exemple',
  'muta-PRIV-3-example': 'Mutuelle Acheel - Formule Privilège 3 - Exemple',
  'muta-PRIV-4-example': 'Mutuelle Acheel - Formule Privilège 4 - Exemple',
  'muta-PRIV-5-example': 'Mutuelle Acheel - Formule Privilège 5 - Exemple',
  'muta-PRIV-6-example': 'Mutuelle Acheel - Formule Privilège 6 - Exemple',
  'muta-PRIV-7-example': 'Mutuelle Acheel - Formule Privilège 7 - Exemple',
  'muta-PRIV-8-example': 'Mutuelle Acheel - Formule Privilège 8 - Exemple',
  'muta-ESSE-1-example': 'Mutuelle Acheel - Formule Essentiel 1 - Exemple',
  'muta-ESSE-2-example': 'Mutuelle Acheel - Formule Essentiel 2 - Exemple',
  'muta-ESSE-3-example': 'Mutuelle Acheel - Formule Essentiel 3 - Exemple',
  'muta-ESSE-4-example': 'Mutuelle Acheel - Formule Essentiel 4 - Exemple',
  'rcda-activity-nomenclature': 'Décennale Axeria - Nomenclature d’activités',
  'rcda-general-terms': 'Décennale Axeria - Conditions générales',
  'rcda-ipid': 'Décennale Axeria - IPID',
  'rc-pro-mac-general-terms-v3': 'RC Pro Hiscox V3 - MAC - Conditions générales',
  'rc-pro-ecom-general-terms-v3': 'RC Pro Hiscox V3 - ECOM - Conditions générales',
  'rc-pro-man-general-terms-v3': 'RC Pro Hiscox V3 - MAN - Conditions générales',
  'rc-pro-media-general-terms-v3': 'RC Pro Hiscox V3 - MEDIA - Conditions générales',
  'rc-pro-misc-general-terms-v3': 'RC Pro Hiscox V3 - MISC - Conditions générales',
  'rc-pro-tech-general-terms-v3': 'RC Pro Hiscox V3 - TECH - Conditions générales',
  'rc-pro-secu-general-terms-v3': 'RC Pro Hiscox V3 - SECU - Conditions générales',
  'rc-pro-tag-general-terms-v3': 'RC Pro Hiscox V3 - TAG - Conditions générales',

  'es-rcph-mac-general-terms': 'Hiscox RC Profesional - MAC - Condiciones generales',
  'es-rcph-man-general-terms': 'Hiscox RC Profesional - MAN - Condiciones generales',
  'es-rcph-media-general-terms': 'Hiscox RC Profesional - MEDIA - Condiciones generales',
  'es-rcph-misc-general-terms': 'Hiscox RC Profesional - MISC - Condiciones generales',
  'es-rcph-tech-general-terms': 'Hiscox RC Profesional - TECH - Condiciones generales',
  'es-rcph-gest-general-terms': 'Hiscox RC Profesional - GEST - Condiciones generales',
  'es-rcph-eco-general-terms': 'Hiscox RC Profesional - ECO - Condiciones generales',
  'es-rcph-eno-general-terms': 'Hiscox RC Profesional - ENO - Condiciones generales',
  'es-rcph-etg-general-terms': 'Hiscox RC Profesional - ETG - Condiciones generales',

  'es-rcph-etg-eco-eno-ipid': 'Hiscox RC Profesional - Empresas y Autónomos - IPID',

  'es-rcph-tech-ipid': 'Hiscox RC Profesional - TECH - IPID',
  'es-rcph-mac-ipid': 'Hiscox RC Profesional - MAC - IPID',
  'es-rcph-man-ipid': 'Hiscox RC Profesional - MAN - IPID',
  'es-rcph-media-ipid': 'Hiscox RC Profesional - MEDIA - IPID',
  'es-rcph-misc-ipid': 'Hiscox RC Profesional - MISC - IPID',
  'es-rcph-gest-ipid': 'Hiscox RC Profesional - GEST - IPID',

  'es-rcph-tech-ipid-liability': 'Hiscox RC Profesional - TECH - IPID - Responsabilidad',
  'es-rcph-mac-ipid-liability': 'Hiscox RC Profesional - MAC - IPID - Responsabilidad',
  'es-rcph-man-ipid-liability': 'Hiscox RC Profesional - MAN - IPID - Responsabilidad',
  'es-rcph-media-ipid-liability': 'Hiscox RC Profesional - MEDIA - IPID - Responsabilidad',
  'es-rcph-misc-ipid-liability': 'Hiscox RC Profesional - MISC - IPID - Responsabilidad',
  'es-rcph-gest-ipid-liability': 'Hiscox RC Profesional - GEST - IPID - Responsabilidad',
}
